<template>
  <section>
    <FormulateForm
      ref="supplierForm"
      name="supplierForm"
    >
      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="$t('Ficha Cadastral')"
      >
        <e-register-form
          ref="registerSupplierForm"
          :person-form="person"
          :is-read-only="isReadOnly"
          :is-edit="isEdit"
          is-supplier
          @clean-person-data="cleanPersonTypeData"
        >
          <template #others-inputs>
            <b-row>
              <b-col md="12">
                <FormulateInput
                  v-if="isReadOnly"
                  id="observation"
                  v-model="person.observation"
                  name="observation"
                  type="label"
                  :label="$t('Observação')"
                />
                <FormulateInput
                  v-else
                  id="observation"
                  v-model="person.observation"
                  name="observation"
                  type="textarea"
                  :label="$t('Observação')"
                />
              </b-col>
            </b-row>
          </template>
        </e-register-form>
      </b-card-actions>

      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="'Características'"
      >
        <!-- each tab has it your own FomulateForm -->
        <b-tabs fill>
          <b-tab :title="$t('Endereços')">
            <e-address-tab
              :is-read-only="isReadOnly"
              :addresses="getAddresses"
              person="Supplier"
              @add-address="(formData) => addItem('addresses', formData)"
              @update-address="(formData) => udpateItem('addresses', formData)"
              @remove-address="(id) => removeItem('addresses', id)"
            />
          </b-tab>

          <b-tab :title="$t('Contatos')">
            <e-contact-tab
              :is-read-only="isReadOnly"
              :contacts="getContacts"
              person="Supplier"
              @add-contact="(formData) => addItem('contacts', formData)"
              @update-contact="(formData) => udpateItem('contacts', formData)"
              @remove-contact="(id) => removeItem('contacts', id)"
            />
          </b-tab>

          <b-tab :title="$t('Dados Bancários')">
            <b-tabs fill>
              <b-tab :title="$t('Banco')">
                <e-bank-tab
                  :is-read-only="isReadOnly"
                  person="Supplier"
                  :banks-list="getBankAccounts"
                  @add-bank="(formData) => addItem('bankAccounts', formData)"
                  @update-bank="(formData) => udpateItem('bankAccounts', formData)"
                  @remove-bank="(id) => removeItem('bankAccounts', id)"
                />
              </b-tab>

              <b-tab :title="$t('PIX')">
                <e-bank-pix-tab
                  :is-read-only="isReadOnly"
                  person="Supplier"
                  :pixs="getPixes"
                  @add-pix="(formData) => addItem('pixes', formData)"
                  @update-pix="(formData) => udpateItem('pixes', formData)"
                  @remove-pix="(id) => removeItem('pixes', id)"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
        </b-tabs>
      </b-card-actions>
    </FormulateForm>

    <e-crud-actions
      :show-save="!isReadOnly"
      :busy="loading"
      @save="saveSupplier"
      @cancel="onCancel"
    />
  </section>
</template>

<script>
import { BTabs, BTab, BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ERegisterForm from '@/views/components/forms/ERegisterForm.vue'
import { EBankPixTab, EAddressTab, EContactTab, EBankTab } from '@/views/components/tabs'
import { mapActions, mapGetters, mapState } from 'vuex'
import { telephoneTypes } from '@/mixins'
import ECrudActions from '@/views/components/ECrudActions.vue'

export default {
  name: 'SupplierMaintain',
  components: {
    BCardActions,
    BTabs,
    BTab,
    BRow,
    BCol,
    EAddressTab,
    EContactTab,
    EBankTab,
    EBankPixTab,
    ERegisterForm,
    ECrudActions,
  },

  mixins: [telephoneTypes],

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      callbackRouteName: 'supplier-list',
      supplierSaved: null,
    }
  },

  computed: {
    ...mapState('pages/purchase/supplier/supplierMaintain', ['person', 'attributes']),
    ...mapGetters('pages/purchase/supplier/supplierMaintain', [
      'getAddresses',
      'getContacts',
      'getBankAccounts',
      'getPixes',
    ]),

    isEdit() {
      return !!this.$route.params.id
    },
  },

  async mounted() {
    if (this.isEdit) {
      await this.loadSupplier()
    }

    if (this.$route.params.callbackRouteName) {
      this.callbackRouteName = this.$route.params.callbackRouteName
    }
  },

  methods: {
    ...mapActions('pages/purchase/supplier/supplierMaintain', [
      'fetchSupplierById',
      'cleanState',
      'addAttributeItem',
      'updateAttributeItem',
      'removeAttributeItem',
      'cleanPersonTypeData',
    ]),

    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderManualMaintain', {
      stPOManualFetchSupplierById: 'fetchSupplierById',
    }),

    async addItem(itemName, formData) {
      try {
        await this.addAttributeItem({ itemName, formData })
      } catch (err) {
        this.showError({ message: err.message })
      }
    },

    async udpateItem(itemName, formData) {
      try {
        await this.updateAttributeItem({ itemName, formData })
      } catch (err) {
        this.showError({ message: err.message })
      }
    },

    async removeItem(itemName, id) {
      try {
        await this.removeAttributeItem({ itemName, id })
      } catch (err) {
        this.showError({ message: err.message })
      }
    },

    async loadSupplier() {
      try {
        this.loading = true
        await this.fetchSupplierById({
          supplierId: this.$route.params.id,
          isReadOnly: this.isReadOnly,
        })
      } catch (err) {
        this.showError({ message: err.message })
      } finally {
        this.loading = false
      }
    },

    async saveSupplier() {
      this.$refs.supplierForm.showErrors()
      if (!this.$refs.registerSupplierForm.validate() || this.$refs.supplierForm.hasErrors) {
        this.showInvalidDataMessage({ message: '<b>Verifique a ficha cadastral</b>' })
        return
      }

      try {
        this.loading = true

        const { addresses, contacts, bankAccounts, pixes, telephonesApi } = this.attributes

        const { birthdate, gender, stateRegistrationType, telephones } = this.person

        const notInTelephonesForm = tApi => telephones.findIndex(t => t.id === tApi.id) < 0
        const telephonesDeleted = telephonesApi
          .filter(tApi => notInTelephonesForm(tApi))
          .map(tDeleted => ({ ...tDeleted, isDeleted: true }))

        const body = {
          ...this.person,
          person: this.person.personType,
          birthdate,
          gender: gender || null,
          stateRegistrationType: stateRegistrationType || null,
          telephones: [...telephones, ...telephonesDeleted],
          addresses,
          contacts,
          bankAccounts,
          pixes,
        }

        const result = await this.$http({
          url: '/api/suppliers',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })

        this.supplierSaved = result.data

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })

        await this.executePosSaveActions()
        this.cleanState()
        this.navigateBack()
      } catch (err) {
        this.showError({ error: err })
      } finally {
        this.loading = false
      }
    },

    onCancel() {
      this.cleanState()
      this.navigateBack()
    },

    async updatePurchaseOrderManualSupplier() {
      await this.stPOManualFetchSupplierById(this.supplierSaved.id)
    },

    async executePosSaveActions() {
      switch (this.callbackRouteName) {
        case 'purchase-order-add-manual':
          await this.updatePurchaseOrderManualSupplier()
          break
        default:
          break
      }
    },

    navigateBack() {
      this.$router.push({ name: this.callbackRouteName })
    },
  },
}
</script>
