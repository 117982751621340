var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "supplierForm", attrs: { name: "supplierForm" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title: _vm.$t("Ficha Cadastral"),
              },
            },
            [
              _c("e-register-form", {
                ref: "registerSupplierForm",
                attrs: {
                  "person-form": _vm.person,
                  "is-read-only": _vm.isReadOnly,
                  "is-edit": _vm.isEdit,
                  "is-supplier": "",
                },
                on: { "clean-person-data": _vm.cleanPersonTypeData },
                scopedSlots: _vm._u([
                  {
                    key: "others-inputs",
                    fn: function () {
                      return [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _vm.isReadOnly
                                  ? _c("FormulateInput", {
                                      attrs: {
                                        id: "observation",
                                        name: "observation",
                                        type: "label",
                                        label: _vm.$t("Observação"),
                                      },
                                      model: {
                                        value: _vm.person.observation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.person,
                                            "observation",
                                            $$v
                                          )
                                        },
                                        expression: "person.observation",
                                      },
                                    })
                                  : _c("FormulateInput", {
                                      attrs: {
                                        id: "observation",
                                        name: "observation",
                                        type: "textarea",
                                        label: _vm.$t("Observação"),
                                      },
                                      model: {
                                        value: _vm.person.observation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.person,
                                            "observation",
                                            $$v
                                          )
                                        },
                                        expression: "person.observation",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title: "Características",
              },
            },
            [
              _c(
                "b-tabs",
                { attrs: { fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("Endereços") } },
                    [
                      _c("e-address-tab", {
                        attrs: {
                          "is-read-only": _vm.isReadOnly,
                          addresses: _vm.getAddresses,
                          person: "Supplier",
                        },
                        on: {
                          "add-address": function (formData) {
                            return _vm.addItem("addresses", formData)
                          },
                          "update-address": function (formData) {
                            return _vm.udpateItem("addresses", formData)
                          },
                          "remove-address": function (id) {
                            return _vm.removeItem("addresses", id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("Contatos") } },
                    [
                      _c("e-contact-tab", {
                        attrs: {
                          "is-read-only": _vm.isReadOnly,
                          contacts: _vm.getContacts,
                          person: "Supplier",
                        },
                        on: {
                          "add-contact": function (formData) {
                            return _vm.addItem("contacts", formData)
                          },
                          "update-contact": function (formData) {
                            return _vm.udpateItem("contacts", formData)
                          },
                          "remove-contact": function (id) {
                            return _vm.removeItem("contacts", id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("Dados Bancários") } },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { fill: "" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("Banco") } },
                            [
                              _c("e-bank-tab", {
                                attrs: {
                                  "is-read-only": _vm.isReadOnly,
                                  person: "Supplier",
                                  "banks-list": _vm.getBankAccounts,
                                },
                                on: {
                                  "add-bank": function (formData) {
                                    return _vm.addItem("bankAccounts", formData)
                                  },
                                  "update-bank": function (formData) {
                                    return _vm.udpateItem(
                                      "bankAccounts",
                                      formData
                                    )
                                  },
                                  "remove-bank": function (id) {
                                    return _vm.removeItem("bankAccounts", id)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("PIX") } },
                            [
                              _c("e-bank-pix-tab", {
                                attrs: {
                                  "is-read-only": _vm.isReadOnly,
                                  person: "Supplier",
                                  pixs: _vm.getPixes,
                                },
                                on: {
                                  "add-pix": function (formData) {
                                    return _vm.addItem("pixes", formData)
                                  },
                                  "update-pix": function (formData) {
                                    return _vm.udpateItem("pixes", formData)
                                  },
                                  "remove-pix": function (id) {
                                    return _vm.removeItem("pixes", id)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-crud-actions", {
        attrs: { "show-save": !_vm.isReadOnly, busy: _vm.loading },
        on: { save: _vm.saveSupplier, cancel: _vm.onCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }